import {
  Organization,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import * as React from "react";
import { useEffect } from "react";
import {
  DocsLink,
  ErrorAlert,
  ErrorContents,
  H2,
  Sonner,
  useDocumentTitle,
} from "ui-components";

import { useBreadcrumbs } from "../../contexts/BreadcrumbContext";
import EditOrganizationModal from "./EditOrganizationModal";

type OrganizationInfoProps = {
  organization: Organization | null;
  organizationRole: OrganizationRole;
  loading: boolean;
  error: ErrorContents | null;
  updateOrganization: (organization: Organization) => void;
  onRetry: () => void;
};

export function OrganizationSettings({
  organization,
  organizationRole,
  loading,
  updateOrganization,
  error,
  onRetry,
}: OrganizationInfoProps) {
  const { setBreadcrumbs } = useBreadcrumbs();

  useDocumentTitle({
    type: "org",
    orgName: organization?.name || "Loading",
    sectionName: "Settings",
  });
  useEffect(() => {
    if (!organization) {
      return;
    }
    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organization.id}`,
        label: organization.name,
      },
      {
        path: `/organizations/${organization.id}/settings`,
        label: "Settings",
      },
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2 items-baseline">
            <H2 className="font-bold m-0">Organization Info</H2>
            <DocsLink path="/organizations/organization" text="Docs" />
          </div>
        </div>
      ) : (
        <div className="relative">
          {organization && (
            <>
              <EditOrganizationModal
                disabled={organizationRole !== OrganizationRoleAdmin}
                organization={organization}
                updateOrganization={(updatedOrganization) => {
                  Sonner.toast("Organization updated");
                  updateOrganization(updatedOrganization);
                }}
              />
              <div className="flex justify-between items-center mb-4">
                <div className="flex gap-2 items-baseline">
                  <H2 className="m-0">Organization Info</H2>
                  <DocsLink path="/organizations/organization" text="Docs" />
                </div>
              </div>
              <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
                <dt>Organization Id:</dt>
                <dd data-testid="organization-id">{organization?.id}</dd>
              </dl>
            </>
          )}
        </div>
      )}
      {error && (
        <div className="w-full relative mt-8">
          <ErrorAlert
            message={error.message}
            action={{
              text: "Retry",
              cb: () => onRetry(),
            }}
          />
        </div>
      )}
    </>
  );
}
