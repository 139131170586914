import {
  Organization,
  OrganizationRole,
  OrganizationRoleAdmin,
  Project,
} from "identity-api";
import * as React from "react";
import { useEffect } from "react";
import {
  ErrorAlert,
  ErrorContents,
  H1,
  H2,
  Skeleton,
  Sonner,
  TooltipProvider,
  useDocumentTitle,
} from "ui-components";
import { Link } from "wouter";

import { apps } from "../../apps";
import { useBreadcrumbs } from "../../contexts/BreadcrumbContext";
import EditProjectModal from "./EditProjectModal";

type ProjectInfoProps = {
  project: Project & { organization: Organization };
  isLoading: boolean;
  error: ErrorContents | null;
  onRetry: () => void;
  onUpdateProject: (project: Project) => void;
  organizationRole?: OrganizationRole;
};

export default function ProjectInfo({
  project,
  isLoading,
  error,
  onRetry,
  onUpdateProject,
  organizationRole,
}: ProjectInfoProps) {
  const { setBreadcrumbs } = useBreadcrumbs();

  useDocumentTitle({
    type: "root",
    sectionName: project?.name || "Loading",
  });
  useEffect(() => {
    if (!project) {
      return;
    }

    setBreadcrumbs(
      {
        path: "/projects",
        label: "Projects",
      },
      {
        path: `/projects/${project.id}`,
        label: project.name,
      },
    );
  }, [project]);

  return (
    <>
      <div className="mb-8">
        <H1>Dashboard</H1>
      </div>
      {error && (
        <div className="w-full relative">
          <ErrorAlert
            message={error.message}
            action={{
              text: "Retry",
              cb: () => onRetry(),
            }}
          />
        </div>
      )}
      {isLoading && (
        <Skeleton className="mb-12 p-6 max-w-none rounded-xl relative" />
      )}
      {project && (
        <>
          <div className="mb-12 p-6 max-w-none rounded-xl relative border border-border bg-card">
            <TooltipProvider>
              <EditProjectModal
                project={project!}
                updateProject={(updatedProject) => {
                  onUpdateProject(updatedProject);
                  Sonner.toast("Project updated");
                }}
                disabled={organizationRole !== OrganizationRoleAdmin}
              />
              <div className="flex justify-between items-center mb-4">
                <H2 className="m-0">
                  <span data-testid="dashboard-project-name">
                    {project.name}
                  </span>
                </H2>
              </div>
              <div className={"grid grid-cols-1 gap-4"}>
                <dl className="grid grid-cols-1 md:flex lg:flex-row gap-2">
                  <dt className={"text-muted-foreground"}>Project ID:</dt>
                  <dd>{project?.id}</dd>
                </dl>
                <dl className="grid grid-cols-1 md:flex lg:flex-row gap-2">
                  <dt className={"text-muted-foreground"}>
                    Organization Name:
                  </dt>
                  <dd>
                    <Link
                      className="underline"
                      to={`/organizations/${project.organizationId}`}
                    >
                      {project?.organization?.name}
                    </Link>
                  </dd>
                </dl>
                <dl className="grid grid-cols-1 md:flex lg:flex-row gap-2">
                  <dt className={"text-muted-foreground"}>Organization ID:</dt>
                  <dd>
                    <Link
                      data-testid="project-info-organisation-link"
                      className="underline"
                      to={`/organizations/${project.organizationId}`}
                    >
                      {project?.organizationId}
                    </Link>
                  </dd>
                </dl>
              </div>
            </TooltipProvider>
          </div>
          {apps.map((section, index) => {
            return (
              <div key={index}>
                <H2>{section.name}</H2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                  {section.items.map((app) => {
                    const Icon = app.icon;
                    return (
                      <Link
                        className="w-full h-40 mt-2 rounded-xl flex flex-col items-center text-xl border border-border justify-center bg-card"
                        to={`/projects/${project?.id}/${app.path}/`}
                        key={app.path}
                      >
                        <Icon size={48} />
                        <div className="mt-2">{app.name}</div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
