import { AuthContext } from "dashboard-common";
import {
  ListTeamsSuccessResponse,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  BasicList,
  DebouncedSearch,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  ListElementSkeleton,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { Link, useLocation } from "wouter";

import { useBreadcrumbs } from "../../../contexts/BreadcrumbContext";
import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import CreateTeamModal from "./CreateTeamModal";
import DeleteTeamModal from "./DeleteTeamModal";

type TeamListViewProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function TeamsListView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: TeamListViewProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const [, setLocation] = useLocation();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher = useDataFetcherState<ListTeamsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const searchQueryRef = useRef<HTMLInputElement>(null);

  useDocumentTitle({
    type: "org",
    orgName: organizationDisplayName,
    sectionName: "Teams",
  });
  useEffect(() => {
    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organizationId}`,
        label: organizationDisplayName,
      },
      {
        path: `/organizations/${organizationId}/teams`,
        label: "Teams",
      },
    );
  }, [organizationId, organizationDisplayName]);

  const fetchTeams = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listTeams({
        parameters: {
          organizationId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          search: searchQueryRef.current?.value || "",
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  return (
    <>
      <div className="max-w-none relative">
        <Title>
          <div className="flex gap-2 items-baseline">
            <H2>Teams</H2>
            <DocsLink path="/organizations/team" text="Docs" />
          </div>
          <CreateTeamModal
            organizationId={organizationId}
            disabled={organizationRole !== OrganizationRoleAdmin}
            onTeamCreated={(team) => {
              Sonner.toast("Team created");
              setLocation(`/organizations/${organizationId}/teams/${team.id}/`);
            }}
          />
        </Title>
      </div>
      <div className="w-full mx-auto pb-20 relative">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="relative pt-4"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage} // number of items per page
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          {organizationRole === OrganizationRoleAdmin && (
            <DebouncedSearch
              ref={searchQueryRef}
              onSearchChange={fetchTeams}
              placeholder="Filter projects by name or id prefix (case-sensitive)..."
              className="w-1/3 min-w-40 mb-4"
            />
          )}
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the teams: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchTeams();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.teams.map((team) => (
              <ListElement
                key={team.id}
                to={`/organizations/${organizationId}/teams/${team.id}/`}
                LinkElement={Link}
              >
                <div className="flex-col flex text-sm basis-2/5">
                  Team name:
                  <span className="text-xl font-semibold">{team.name}</span>
                </div>
                <div className="flex-col flex text-sm">
                  Organization id:
                  <span className="text-xl font-semibold">{team.id}</span>
                </div>
                <div className="ml-auto flex gap-2 mr-4">
                  {organizationRole === OrganizationRoleAdmin && (
                    <DeleteTeamModal
                      teamId={team.id}
                      organizationId={organizationId}
                      onTeamDeleted={() => {
                        fetchTeams();
                        Sonner.toast("Team deleted");
                      }}
                    />
                  )}
                </div>
              </ListElement>
            ))}
            {dataFetcher.data && !dataFetcher.data.teams.length && (
              <EmptyState message="There are no teams" />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
