import * as React from "react";
import { Badge, Tooltip, TooltipContent, TooltipTrigger } from "ui-components";
import { TooltipProvider } from "ui-components/src";
import { Link } from "wouter";

import { MSquaredSVG } from "../../MSquaredSVG";

export function Branding() {
  return (
    <Link to="/">
      <div className="p-2 py-3 hover:bg-sidebar-accent rounded-md flex flex-row items-center gap-3">
        <MSquaredSVG />
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Badge variant="outline" className={"uppercase font-normal"}>
                Alpha
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                MSquared is in Early Access. Features and pricing are still
                evolving as we grow.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </Link>
  );
}
