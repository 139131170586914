import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { Organization } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from "ui-components";
import * as z from "zod";

import { createIdentityServiceClient } from "../../IdentityServiceClient";

type CreateOrganizationModalProps = {
  onOrganizationCreated: (organization: Organization) => void;
  children: React.ReactNode;
};

const createOrganization = (
  organizationName: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized?: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createOrganization({
      parameters: {},
      body: {
        name: organizationName,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create organization");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
});

function CreateOrganizationModal(props: CreateOrganizationModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const project = await createOrganization(
        data.name,
        getAccessToken,
        onUnauthorized,
      );

      props.onOrganizationCreated(project);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create Organization
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <Label className="mb-2 inline-flex">
                      Organization Name
                    </Label>
                    <Input
                      type="text"
                      placeholder="Organization Name"
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="mt-4">
              {error && (
                <p className="flex-1 text-destructive self-center">
                  <div>There was a problem creating the organization:</div>
                  <div data-testid="create-org-error">{error.message}</div>
                </p>
              )}
              <Button
                disabled={!form.formState.isValid || isSubmitting}
                variant={error ? "destructive" : "default"}
              >
                {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateOrganizationModal;
