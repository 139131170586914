import { Session } from "dashboard-api";
import { AuthProvider } from "dashboard-common";
import * as React from "react";
import { useCallback } from "react";
import { NotFound, SidebarInset, SidebarProvider, Sonner } from "ui-components";
import { Redirect, Route, Switch } from "wouter";

import AcceptInvite from "./components/AcceptInvite/AcceptInvite";
import { Breadcrumbs } from "./components/Breadcrumbs/Breadcrumbs";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import { Home } from "./components/Home/Home";
import OrganizationDashboard from "./components/OrganizationDashboard/OrganizationDashboard";
import ProfileView from "./components/ProfileView/ProfileView";
import ProjectDashboard from "./components/ProjectDashboard/ProjectDashboard";
import { SidebarOrganization } from "./components/Sidebar/SidebarOrganization";
import { SidebarProject } from "./components/Sidebar/SidebarProject";
import { SidebarRoot } from "./components/Sidebar/SidebarRoot";
import { BreadcrumbsProvider } from "./contexts/BreadcrumbContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import { MutatableThemeProvider } from "./state/MutatableThemeContext";

export type DashboardProps = {
  user: Session["user"];
  sessionToken: string;
  onUnauthorized: () => void;
};

function DashboardInner({
  user,
  sessionToken,
  onUnauthorized,
}: DashboardProps) {
  const getAccessToken = useCallback(() => {
    return Promise.resolve(sessionToken);
  }, [sessionToken]);

  return (
    <MutatableThemeProvider defaultTheme="dark" storageKey="ui-theme">
      <SidebarProvider>
        <BreadcrumbsProvider>
          <AuthProvider
            user={user}
            getAccessToken={getAccessToken}
            onUnauthorized={onUnauthorized}
          >
            <ProjectProvider
              getAccessToken={getAccessToken}
              onUnauthorized={onUnauthorized}
            >
              <Sonner />
              <Switch>
                <Route path={`/organizations/:organizationId/*?`}>
                  {({ organizationId }) => (
                    <SidebarOrganization organizationId={organizationId} />
                  )}
                </Route>
                <Route path={`/projects/:projectId/*`}>
                  {({ projectId }) => <SidebarProject projectId={projectId} />}
                </Route>
                <Route>
                  <SidebarRoot />
                </Route>
              </Switch>
              <SidebarInset>
                <Breadcrumbs />
                <Switch>
                  <Route path={`/`}>
                    <Redirect to={`/projects`} />
                  </Route>
                  <Route path={`/profile`}>
                    <ProfileView />
                  </Route>
                  <Route path={`/projects/:projectId/*`}>
                    <ProjectDashboard
                      user={user}
                      sessionToken={sessionToken}
                      onUnauthorized={onUnauthorized}
                    />
                  </Route>
                  <Route path={`/projects`}>
                    <Home />
                  </Route>
                  <Route path={`/organizations`}>
                    <Home />
                  </Route>
                  <Route path={`/organizations/:organizationId/*?`}>
                    <OrganizationDashboard />
                  </Route>
                  <Route path={`/accept-invite/:organizationId/:inviteId`}>
                    <AcceptInvite />
                  </Route>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </SidebarInset>
              <CookieBanner />
            </ProjectProvider>
          </AuthProvider>
        </BreadcrumbsProvider>
      </SidebarProvider>
    </MutatableThemeProvider>
  );
}

function isEqual(prevProps: DashboardProps, nextProps: DashboardProps) {
  return deepEqual(prevProps, nextProps);
}

export const Dashboard = React.memo(DashboardInner, isEqual);

function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    return a.every((elem, index) => {
      return deepEqual(elem, b[index]);
    });
  }

  if (
    typeof a === "object" &&
    typeof b === "object" &&
    a !== null &&
    b !== null
  ) {
    if (Array.isArray(a) || Array.isArray(b)) {
      return false;
    }

    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);
    if (
      keys1.length !== keys2.length ||
      !keys1.every((key) => keys2.includes(key))
    ) {
      return false;
    }

    for (const key in a) {
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
}
