import { AuthContext } from "dashboard-common";
import {
  ListMemberPermissionsSuccessResponse,
  Member,
  MemberPermission,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  Sonner,
  Title,
  useDataFetcherState,
} from "ui-components";
import { ListElementSkeleton } from "ui-components/src";

import { useBreadcrumbs } from "../../../../contexts/BreadcrumbContext";
import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import { permissionResourceToDisplayName } from "../../permissionResources";
import CreateMemberPermissionModal from "./CreateMemberPermissionModal";
import DeleteMemberPermissionModal from "./DeleteMemberPermissionModal";

type MemberPermissionsListViewProps = {
  memberId: string;
  member: Member | null;
  organizationId: string;
  organizationDisplayName: string;
  organizationRole: OrganizationRole;
};

export function MemberPermissionsListView({
  memberId,
  member,
  organizationId,
  organizationDisplayName,
  organizationRole,
}: MemberPermissionsListViewProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { getAccessToken, onUnauthorized, user } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListMemberPermissionsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchMemberPermissions = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listPermissionsForMember({
        parameters: {
          organizationId,
          memberId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    memberId,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    if (organizationRole === OrganizationRoleAdmin || user?.id === memberId) {
      fetchMemberPermissions();
    } else {
      dataFetcher.setIsLoading(false);
    }
  }, [
    fetchMemberPermissions,
    organizationRole,
    dataFetcher,
    user?.id,
    memberId,
  ]);

  useEffect(() => {
    if (!member) {
      return;
    }

    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organizationId}`,
        label: organizationDisplayName,
      },
      {
        path: `/organizations/${organizationId}/members`,
        label: "Members",
      },
      {
        path: `/organizations/${organizationId}/members/${member.id}`,
        label: member.userInfo.displayName || memberId,
      },
      {
        path: `/organizations/${organizationId}/members/${member.id}/permissions`,
        label: "Permissions",
      },
    );
  }, [organizationId, organizationDisplayName, member]);

  return (
    <>
      <Title>
        <div className="flex gap-2 items-baseline">
          <H2>Member Permissions</H2>
          <DocsLink
            path="/organizations/members/member-permissions"
            text="Docs"
          />
        </div>
        <CreateMemberPermissionModal
          memberId={memberId}
          organizationId={organizationId}
          onMemberPermissionCreated={() => {
            fetchMemberPermissions();
            Sonner.toast("Individual Permission created");
          }}
          disabled={organizationRole !== OrganizationRoleAdmin}
        />
      </Title>
      <div className="w-full min-h-full mx-auto pb-20 relative">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="pt-4"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the member's permissions: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchMemberPermissions();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.memberPermissions.map(
              (teamPermission: MemberPermission) => (
                <ListElement noLink key={teamPermission.id}>
                  <div className="flex-col flex text-sm basis-1/3">
                    Project ID:
                    <span className="text-xl font-semibold">
                      {teamPermission.projectId}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm basis-1/3">
                    Verb:
                    <span className="text-xl font-semibold">
                      {teamPermission.permission.verb}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm">
                    Resource:
                    <span className="text-xl font-semibold">
                      {permissionResourceToDisplayName(
                        teamPermission.permission.resource,
                      )}
                    </span>
                  </div>
                  <div className="ml-auto flex gap-2 mr-4">
                    <DeleteMemberPermissionModal
                      memberId={memberId}
                      organizationId={organizationId}
                      permissionId={teamPermission.id}
                      onMemberPermissionDeleted={() => {
                        fetchMemberPermissions();
                        Sonner.toast("Individual Permission deleted");
                      }}
                    />
                  </div>
                </ListElement>
              ),
            )}
            {user?.id !== memberId &&
              organizationRole !== OrganizationRoleAdmin && (
                <EmptyState message="You don't have permission to view this member's permissions" />
              )}
            {dataFetcher.data && !dataFetcher.data.memberPermissions.length && (
              <EmptyState message="There are no permissions" />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
