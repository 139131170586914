import { AuthContext } from "dashboard-common";
import { APIKey, OrganizationRole } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import {
  ErrorAlert,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { H2 } from "ui-components/src/components/ui";
import { Link, useParams } from "wouter";

import { useBreadcrumbs } from "../../../contexts/BreadcrumbContext";
import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import { APIKeyPermissionsListView } from "./APIKeyPermissionsListView/APIKeyPermissionsListView";

type APIKeyDashboardProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function APIKeyDashboard({
  organizationId,
  organizationDisplayName,
}: APIKeyDashboardProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { apiKeyId } =
    useParams<"/organizations/:organizationId/teams/:apiKeyId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);

  const dataFetcher = useDataFetcherState<APIKey>();
  const fetchData = useCallback(() => {
    dataFetcher.setIsLoading(true);

    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getAPIKey({
        parameters: {
          apiKeyId,
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, onUnauthorized, apiKeyId, organizationId, dataFetcher]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const apiKey = dataFetcher.data;

  useDocumentTitle({
    type: "org",
    orgName: organizationDisplayName,
    sectionName: "API Keys",
  });
  useEffect(() => {
    if (!apiKey) {
      return;
    }

    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organizationId}`,
        label: organizationDisplayName,
      },
      {
        path: `/organizations/${organizationId}/api-keys`,
        label: "API Keys",
      },
      {
        path: `/organizations/${organizationId}/api-keys/${apiKey.id}`,
        label: apiKey.id,
      },
    );
  }, [organizationId, organizationDisplayName, apiKey]);

  return (
    <>
      <div className="w-full">
        {dataFetcher.isLoading && (
          <div className="mb-20">
            <H2>Loading...</H2>
          </div>
        )}
        {dataFetcher.error && (
          <div className="w-full relative">
            {dataFetcher.error.code === 404 ? (
              <ErrorAlert
                message="API Key not found"
                link={{
                  text: "Go back",
                  to: `/organizations/${organizationId}/api-keys/`,
                  element: Link,
                }}
              />
            ) : (
              <ErrorAlert
                message={dataFetcher.error.message}
                action={{
                  text: "Retry",
                  cb: () => fetchData(),
                }}
              />
            )}
          </div>
        )}
        {apiKey && (
          <>
            <H2 className="font-bold m-0">{apiKey.name}</H2>
            <p>{apiKey.id}</p>
            <p>{apiKey.createdAt}</p>
            <APIKeyPermissionsListView
              apiKeyId={apiKeyId}
              organizationId={organizationId}
              organizationDisplayName={organizationDisplayName}
            />
          </>
        )}
      </div>
    </>
  );
}
