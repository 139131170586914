import { Book } from "lucide-react";
import * as React from "react";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "ui-components";

import Feedback from "./Feedback";

export function SupportMenu() {
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton asChild size="sm">
          <a href="https://docs.mserve.io" target="_blank" rel="noreferrer">
            <Book />
            Docs
          </a>
        </SidebarMenuButton>
      </SidebarMenuItem>
      <SidebarMenuItem>
        <Feedback />
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
