import { Session } from "dashboard-api";
import { DashboardApp, DashboardAppProps } from "dashboard-common";
import * as React from "react";
import * as ReactShadow from "react-shadow";
import ReactShadowDefault from "react-shadow";
import { LoadingSpinnerFull } from "ui-components";

import { useBreadcrumbs } from "./contexts/BreadcrumbContext";
import { useTheme } from "./state/MutatableThemeContext";
// @ts-expect-error - react-shadow default export is inconsistently imported. Use createProxy instead.
const ShadowRoot: typeof ReactShadowDefault = ReactShadow.createProxy();

type Props = {
  app: DashboardApp;
  projectId: string;
  projectName?: string;
  user: Session["user"];
  getAccessToken: () => Promise<string>;
  onUnauthorized: () => void;
};

export function RenderedApp(props: Props) {
  const { theme } = useTheme();
  const { setBreadcrumbs } = useBreadcrumbs();

  // Await the loading of the component and then render it
  const [component, setComponent] = React.useState<{
    component: React.ComponentType<DashboardAppProps>;
  } | null>(null);
  React.useEffect(() => {
    props.app.getComponent().then((comp) => {
      setComponent({ component: comp });
    });
  }, [props.app]);

  if (!component) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <LoadingSpinnerFull />
      </div>
    );
  }

  const Component = component.component;
  return (
    <ShadowRoot.div className="h-full">
      <Component
        projectId={props.projectId}
        projectName={props.projectName}
        theme={theme}
        user={props.user}
        getAccessToken={props.getAccessToken}
        onUnauthorized={props.onUnauthorized}
        setBreadcrumbs={setBreadcrumbs}
      />
    </ShadowRoot.div>
  );
}
