import { AuthContext } from "dashboard-common";
import { ChevronsUpDown, LogOut, Moon, Sun, UserCircle } from "lucide-react";
import * as React from "react";
import { useContext } from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "ui-components";
import { Link } from "wouter";

import { useSession } from "../../auth/useSession";
import { useTheme } from "../../state/MutatableThemeContext";
import { THEME } from "../../types";

export function UserMenu() {
  const { isMobile } = useSidebar();
  const { theme, setTheme } = useTheme();
  const { signOut } = useSession();
  const { user } = useContext(AuthContext);

  const toggleTheme = () => {
    if (theme === THEME.dark) {
      setTheme(THEME.light);
      return;
    }

    setTheme(THEME.dark);
  };

  if (!user) {
    return null;
  }

  return (
    <SidebarMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <SidebarMenuButton
            size="lg"
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            data-testid="sidebar-user-menu"
          >
            <Avatar className="h-8 w-8 rounded-lg">
              <AvatarImage src={user.picture || ""} alt={user.name || "User"} />
              <AvatarFallback className="rounded-lg">CN</AvatarFallback>
            </Avatar>
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-semibold">{user.name}</span>
              <span className="truncate text-xs">{user.email}</span>
            </div>
            <ChevronsUpDown className="ml-auto size-4" />
          </SidebarMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
          side={isMobile ? "bottom" : "right"}
          align="end"
          sideOffset={4}
        >
          <DropdownMenuLabel className="p-0 font-normal">
            <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage
                  src={user.picture || ""}
                  alt={user.name || "User"}
                />
                <AvatarFallback className="rounded-lg">CN</AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <Link to={`/profile`}>
              <DropdownMenuItem data-testid="sidebar-user-menu-profile">
                <UserCircle className={"size-4"} />
                Profile
              </DropdownMenuItem>
            </Link>
            <DropdownMenuItem onClick={toggleTheme}>
              {THEME.dark === theme ? (
                <Sun className="size-4" />
              ) : (
                <Moon className="size-4" />
              )}
              {THEME.dark === theme ? "Light Mode" : "Dark Mode"}
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => signOut()}>
            <LogOut />
            Sign Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </SidebarMenuItem>
  );
}
