import { Building, Plus } from "lucide-react";
import * as React from "react";
import { useEffect } from "react";
import {
  DropdownMenuItem,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "ui-components";
import { SidebarGroupContent } from "ui-components/src";
import { Link, useLocation } from "wouter";

import { useProject } from "../../contexts/ProjectContext";
import CreateOrganizationModal from "../ProfileView/CreateOrganizationModal";
import { Branding } from "./Branding";
import { SelectableDropdown } from "./SelectableDropdown";
import { SupportMenu } from "./SupportMenu";
import { UserMenu } from "./UserMenu";

type Item = {
  name: string;
  path: string;
  testIdPostfix?: string;
};

const Items: Item[] = [
  {
    name: "Projects",
    path: "projects",
    testIdPostfix: "projects",
  },
  {
    name: "Teams",
    path: "teams",
  },
  {
    name: "Members",
    path: "members",
  },
  {
    name: "API Keys",
    path: "api-keys",
  },
  {
    name: "Invites",
    path: "invites",
  },
  {
    name: "Billing",
    path: "billing",
    testIdPostfix: "billing",
  },
  {
    name: "Settings",
    path: "settings",
    testIdPostfix: "settings",
  },
];

type Props = {
  organizationId: string;
};

export function SidebarOrganization({ organizationId }: Props) {
  const [location] = useLocation();
  const currentPath = location.split("/")[3];
  const [, navigate] = useLocation();
  const { getOrganizationById, getOrganizations, refresh } = useProject();
  const organization = getOrganizationById(organizationId);
  const organizations = getOrganizations();

  useEffect(() => {
    if (!organization) {
      refresh();
    }
  }, []);

  if (!organization) {
    return <Sidebar variant="inset"></Sidebar>;
  }

  return (
    <Sidebar variant="inset">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <Branding />
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SelectableDropdown
              icon={<Building />}
              text={organization.name}
              link={`/organizations/${organization.id}`}
              createComponent={
                <CreateOrganizationModal
                  onOrganizationCreated={(organization) => {
                    navigate(`/organizations/${organization.id}`);
                  }}
                >
                  <DropdownMenuItem
                    className="gap-2 p-2"
                    onSelect={(e) => e.preventDefault()}
                  >
                    <div className="flex size-6 items-center justify-center rounded-sm border">
                      <Plus className="size-4" />
                    </div>
                    Create Organization
                  </DropdownMenuItem>
                </CreateOrganizationModal>
              }
              options={organizations!.map((organization) => {
                return {
                  text: organization.name,
                  link: `/organizations/${organization.id}/`,
                };
              })}
            />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Organization</SidebarGroupLabel>
          <SidebarMenu>
            {Items.map((item) => (
              <SidebarMenuItem key={item.name}>
                <Link to={`/organizations/${organizationId}/${item.path}`}>
                  <SidebarMenuButton
                    data-testid={
                      item.testIdPostfix ? `sidebar-${item.testIdPostfix}` : ""
                    }
                    isActive={currentPath === item.path}
                  >
                    <span>{item.name}</span>
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
        <SidebarGroup className="mt-auto">
          <SidebarGroupContent>
            <SupportMenu />
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <UserMenu />
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
