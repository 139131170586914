import { ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { ReactElement } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  SidebarMenuButton,
} from "ui-components";
import { Link } from "wouter";

type Props = {
  icon: ReactElement;
  text: string;
  link: string;
  options: {
    text: string;
    link: string;
  }[];
  createComponent: React.ReactNode;
};

export const gradients = {
  sunburst: "bg-gradient-to-t from-yellow-400 to-orange-600",
  coralreef: "bg-gradient-to-t from-teal-400 to-pink-400",
  sunsetglow: "bg-gradient-to-t from-red-500 to-purple-500",
  meadow: "bg-gradient-to-t from-green-400 to-lime-500",
  twilight: "bg-gradient-to-t from-blue-500 to-purple-600",
  blush: "bg-gradient-to-t from-rose-400 to-pink-500",
  aurora: "bg-gradient-to-t from-indigo-500 to-cyan-400",
  lagoon: "bg-gradient-to-t from-cyan-500 to-blue-500",
  dawn: "bg-gradient-to-t from-orange-300 to-pink-500",
  oahu: "bg-gradient-to-t from-orange-400 to-sky-400",
};

export type GradientName = keyof typeof gradients;

export const getGradientClassNamesForName = (name: string): string => {
  const gradientKeys = Object.keys(gradients) as GradientName[];
  const numberOfGradients = gradientKeys.length;

  const hash = Array.from(name).reduce(
    (acc, char) => acc + char.charCodeAt(0),
    0,
  );
  const gradientIndex = hash % numberOfGradients;

  return gradients[gradientKeys[gradientIndex]];
};

export function SelectableDropdown({
  icon,
  text,
  link,
  createComponent,
  options,
}: Props) {
  const showSeparator = options.length > 0;

  return (
    <DropdownMenu>
      <div className={"flex flex-row"}>
        <div className="grow">
          <Link href={link}>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div
                className={`flex aspect-square size-7 p-1.5 items-center justify-center rounded-md ext-sidebar-primary-foreground ${getGradientClassNamesForName(text)} text-white`}
              >
                {icon}
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{text}</span>
              </div>
            </SidebarMenuButton>
          </Link>
        </div>
        <div>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton className={"h-full"}>
              <ChevronsUpDown className="" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
        </div>
      </div>
      <DropdownMenuContent className="w-48" align="end">
        {options.map((option) => (
          <Link href={option.link} key={option.link}>
            <DropdownMenuItem key={option.text} className="gap-2 p-2">
              <div className="flex size-6 items-center justify-center rounded-sm border">
                {icon}
              </div>
              {option.text}
            </DropdownMenuItem>
          </Link>
        ))}
        {showSeparator && <DropdownMenuSeparator />}
        {createComponent}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
