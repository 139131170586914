import { Building, CircleUserRound, Folder, LucideIcon } from "lucide-react";
import * as React from "react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "ui-components";
import { SidebarGroupContent } from "ui-components/src";
import { Link, useLocation } from "wouter";

import { Branding } from "./Branding";
import { SupportMenu } from "./SupportMenu";
import { UserMenu } from "./UserMenu";

type Item = {
  icon: LucideIcon;
  name: string;
  path: string;
};

const Items: Item[] = [
  {
    icon: Folder,
    name: "Projects",
    path: "projects",
  },
  {
    icon: Building,
    name: "Organizations",
    path: "organizations",
  },
  {
    icon: CircleUserRound,
    name: "Profile",
    path: "profile",
  },
];

export function SidebarRoot() {
  const [location] = useLocation();
  const currentPath = location.split("/")[1];

  return (
    <Sidebar variant="inset">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <Branding />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {Items.map((item) => (
              <SidebarMenuItem key={item.path}>
                <Link to={`/${item.path}`}>
                  <SidebarMenuButton
                    isActive={currentPath === item.path}
                    data-testid={`sidebar-root-${item.path}`}
                  >
                    <item.icon />
                    <span>{item.name}</span>
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
        <SidebarGroup className="mt-auto">
          <SidebarGroupContent>
            <SupportMenu />
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <UserMenu />
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
