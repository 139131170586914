import * as React from "react";
import { Fragment } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Separator,
  SidebarTrigger,
} from "ui-components";
import { Link } from "wouter";

import { useBreadcrumbs } from "../../contexts/BreadcrumbContext";

export function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumbs();

  let list;
  if (breadcrumbs.length === 0) {
    list = null;
  } else if (breadcrumbs.length === 1) {
    list = (
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>{breadcrumbs[0].label}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    );
  } else {
    list = (
      <Breadcrumb>
        <BreadcrumbList>
          {breadcrumbs.slice(0, breadcrumbs.length - 1).map((breadcrumb) => (
            <Fragment key={breadcrumb.path}>
              <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink asChild={true}>
                  <Link href={breadcrumb.path}>{breadcrumb.label}</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" />
            </Fragment>
          ))}
          <BreadcrumbItem>
            <BreadcrumbPage>
              {breadcrumbs[breadcrumbs.length - 1].label}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    );
  }

  return (
    <header className="flex h-16 shrink-0 items-center gap-2">
      <div className="flex items-center gap-2 px-4">
        <SidebarTrigger className="-ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        {list}
      </div>
    </header>
  );
}
