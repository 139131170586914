import { AuthContext } from "dashboard-common";
import { BillingInfo, Invoice } from "identity-api";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { DataFetcherState, useDataFetcherState } from "ui-components";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

// Poll for a max of 5 attempts, at 3, 6, 9, 12, 30s
const MAX_POLLING_ATTEMPTS = 5;
const POLLING_INTERVAL_MS = 1000;
const POLLING_MULTIPLIER = 3;

type Params = {
  organizationId: string;
};

type BillingInfoContext = {
  isPollingForUpgrade: boolean;
  pollForUpgrade: () => void;
  stopPollingForUpgrade: () => void;
  fetchBillingInfo: () => void;
  fetchUpcomingInvoice: () => void;
  billingInfo: DataFetcherState<BillingInfo>;
  upcomingInvoice: DataFetcherState<Invoice>;
};

export function useBillingInfo({ organizationId }: Params): BillingInfoContext {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isPollingForUpgrade, setIsPollingForUpgrade] = useState(false);
  const billingInfo = useDataFetcherState<BillingInfo>();
  const upcomingInvoice = useDataFetcherState<Invoice>();
  const pollTimeout = useRef<ReturnType<typeof setTimeout>>();

  const identityServiceClient = useMemo(
    () => createIdentityServiceClient(getAccessToken, onUnauthorized),
    [getAccessToken, onUnauthorized],
  );

  const fetchBillingInfo =
    useCallback(async (): Promise<BillingInfo | null> => {
      const response = await identityServiceClient.getBillingInfo({
        parameters: {
          organizationId,
        },
        body: null,
      });

      if (response.code !== 200) {
        billingInfo.setError(response.body);
        return null;
      }

      billingInfo.setData(response.body);
      return response.body;
    }, [getAccessToken, onUnauthorized, organizationId, billingInfo]);
  const fetchUpcomingInvoice =
    useCallback(async (): Promise<Invoice | null> => {
      const response = await identityServiceClient.getUpcomingInvoice({
        parameters: {
          organizationId,
        },
        body: null,
      });

      if (response.code !== 200) {
        upcomingInvoice.setError(response.body);
        return null;
      }

      console.log("Fetched upcoming invoice", response.body);
      upcomingInvoice.setData(response.body);
      return response.body;
    }, [getAccessToken, onUnauthorized, organizationId, upcomingInvoice]);

  const poll = useCallback(async (attempt: number) => {
    await new Promise((resolve) => {
      pollTimeout.current = setTimeout(
        resolve,
        attempt * POLLING_INTERVAL_MS * POLLING_MULTIPLIER,
      );
    });

    const billingInfo = await fetchBillingInfo();

    if (!billingInfo) {
      console.log("Stopping poll for upgrade - error fetching billing info");
      setIsPollingForUpgrade(false);
      return;
    }

    if (billingInfo.subscriptionTier === "payg") {
      console.log("Stopping poll for upgrade - user upgraded");
      setIsPollingForUpgrade(false);
      return;
    }

    console.log("User not yet upgraded. Polling again...");

    if (attempt + 1 > MAX_POLLING_ATTEMPTS) {
      console.log("Stopping poll for upgrade - max attempts reached");
      setIsPollingForUpgrade(false);
      return;
    }

    poll(attempt + 1);
  }, []);

  const pollForUpgrade = useCallback(() => {
    setIsPollingForUpgrade(true);
    poll(1).then();
  }, []);

  const stopPollingForUpgrade = useCallback(() => {
    setIsPollingForUpgrade(false);
    if (pollTimeout.current) {
      clearTimeout(pollTimeout.current);
    }
  }, []);

  return {
    isPollingForUpgrade,
    pollForUpgrade,
    stopPollingForUpgrade,
    fetchBillingInfo,
    fetchUpcomingInvoice,
    billingInfo,
    upcomingInvoice,
  };
}
