import * as React from "react";
import { useCallback } from "react";
import { Skeleton } from "ui-components";
import { Separator } from "ui-components/src";

import { useSession } from "./auth/useSession";
import { Dashboard } from "./Dashboard";
import { MSquaredSVG } from "./MSquaredSVG";
import { MutatableThemeProvider } from "./state/MutatableThemeContext";

function App() {
  const { session, status, getCsrfToken, signOut } = useSession();
  const isLoading = status === "loading";

  const user = session?.user;

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    const csrfResponse = await getCsrfToken();
    if (!csrfResponse || csrfResponse.type === "error") {
      console.error("Failed to fetch CSRF token");
      return;
    }

    const csrfTokenInput = document.createElement("input");
    csrfTokenInput.type = "hidden";
    csrfTokenInput.name = "csrfToken";
    csrfTokenInput.value = csrfResponse.token;
    form.appendChild(csrfTokenInput);

    form.submit();
  };

  const onUnauthorized = useCallback(async () => {
    await signOut();
  }, []);

  if (isLoading) {
    return (
      <MutatableThemeProvider defaultTheme="dark" storageKey="ui-theme">
        <Skeleton className="w-full h-navbar-height rounded-none" />
        <Skeleton className="w-full h-body-height rounded-none" />
      </MutatableThemeProvider>
    );
  }

  if (!session) {
    return (
      <div className="absolute flex flex-col left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 border p-8 rounded-xl w-[400px] gap-y-5">
        <div className="flex flex-col items-center justify-center w-full gap-y-2">
          <MSquaredSVG />
          <p className="text-2xl text-center">Sign in to continue</p>
        </div>
        <Separator />
        <form
          className={"flex items-center justify-center w-full"}
          action="/api/auth/signin/google"
          method="POST"
          onSubmit={handleFormSubmit}
        >
          <input
            type="hidden"
            name="callbackUrl"
            value={window.location.href}
          />
          <button
            onClick={() => {}}
            className="justify-center px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150"
          >
            <img
              className="w-6 h-6"
              src="/google-icon.svg"
              loading="lazy"
              alt="google logo"
            />
            <span>Sign in with Google</span>
          </button>
        </form>
        <Separator />
        <span className={"text-sm"}>
          By signing in, you acknowledge that you agree to our{" "}
          <a href={"/terms"} target={"_blank"} className={"font-bold"}>
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href={"https://msquared.io/privacy-policy"}
            target={"_blank"}
            className={"font-bold"}
          >
            Privacy Policy
          </a>
          .
        </span>
      </div>
    );
  }

  return (
    <Dashboard
      user={user!}
      onUnauthorized={onUnauthorized}
      sessionToken={session.sessionToken}
    />
  );
}

export default App;
