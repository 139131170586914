import * as React from "react";

import TermsOfServiceText from "./body.mdx";
import { MutatableThemeProvider } from "./state/MutatableThemeContext";

function TermsOfService() {
  return (
    <MutatableThemeProvider defaultTheme="dark" storageKey="ui-theme">
      <div className={"flex w-full justify-center items-center px-4 py-8"}>
        <div className={"max-w-prose w-full rendered-markdown"}>
          <TermsOfServiceText />
        </div>
      </div>
    </MutatableThemeProvider>
  );
}

export default TermsOfService;
