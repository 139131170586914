import { Organization, Project } from "identity-api";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { createIdentityServiceClient } from "../IdentityServiceClient";

interface ProjectProviderProps {
  getAccessToken: () => Promise<string>;
  onUnauthorized: () => void;
  children: ReactNode;
}

interface ProjectContextType {
  refresh: () => void;
  getOrganizationByProjectId: (projectId: string) => Organization | null;
  getProjectById: (projectId: string) => Project | null;
  getOrganizations: () => Organization[];
  getProjectsInOrgByProjectId: (projectId: string) => Project[];
  getOrganizationById: (organizationId: string) => Organization | null;
}

const ProjectContext = createContext<ProjectContextType | null>(null);

export const ProjectProvider = ({
  getAccessToken,
  onUnauthorized,
  children,
}: ProjectProviderProps) => {
  const [projects, setProjects] = useState<Project[]>(
    () => getCachedData("projects") || [],
  );
  const [organizations, setOrganizations] = useState<Organization[]>(
    () => getCachedData("organizations") || [],
  );

  const fetchAndCacheProjects = async (): Promise<void> => {
    const client = createIdentityServiceClient(getAccessToken, onUnauthorized);
    const response = await client.listVisibleProjects({
      parameters: { offset: 0, limit: 100 },
      body: null,
    });

    if (response.code !== 200) {
      console.error("Failed to load projects: ", response.body.message);
      return;
    }

    setProjects(response.body.projects);
    cacheData("projects", response.body.projects);
  };

  const fetchAndCacheOrganizations = async (): Promise<void> => {
    const client = createIdentityServiceClient(getAccessToken, onUnauthorized);
    const response = await client.listOrganizations({
      parameters: { limit: 100 },
      body: null,
    });

    if (response.code !== 200) {
      console.error("Failed to load organizations: ", response.body.message);
      return;
    }

    setOrganizations(response.body.organizations);
    cacheData("organizations", response.body.organizations);
  };

  const getOrganizationByProjectId = (
    projectId: string,
  ): Organization | null => {
    const project = projects.find((proj) => proj.id === projectId);
    if (!project) return null;
    return (
      organizations.find((org) => org.id === project.organizationId) || null
    );
  };

  const getProjectById = (projectId: string): Project | null => {
    return projects.find((proj) => proj.id === projectId) || null;
  };

  const getOrganizations = (): Organization[] => {
    return organizations;
  };

  const getProjectsInOrgByProjectId = (projectId: string): Project[] => {
    const project = projects.find((proj) => proj.id === projectId);
    if (!project) return [];
    return projects.filter(
      (proj) => proj.organizationId === project.organizationId,
    );
  };

  const getOrganizationById = (organizationId: string): Organization | null => {
    return organizations.find((org) => org.id === organizationId) || null;
  };

  const refresh = useCallback(() => {
    fetchAndCacheProjects();
    fetchAndCacheOrganizations();
  }, [fetchAndCacheProjects, fetchAndCacheOrganizations]);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        getOrganizationByProjectId,
        getProjectById,
        getOrganizations,
        getProjectsInOrgByProjectId,
        getOrganizationById,
        refresh,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = (): ProjectContextType => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

const cacheData = (key: string, data: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(`Failed to cache ${key}:`, error);
  }
};

const getCachedData = (key: string): any | null => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Failed to retrieve cached ${key}:`, error);
    return null;
  }
};
