import { OrganizationRole } from "identity-api";
import { CheckCircle, ShieldOff } from "lucide-react";
import * as React from "react";
import { useEffect } from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  ErrorAlert,
  H2,
  LoadingSpinner,
  Skeleton,
  Title,
  useDocumentTitle,
} from "ui-components";

import { useBreadcrumbs } from "../../../contexts/BreadcrumbContext";
import { useBillingInfo } from "./billing-info-hook";

type InviteListViewProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole: OrganizationRole;
};

export function BillingView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: InviteListViewProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const searchParams = new URLSearchParams(window.location.search);
  const upgradedSearchParam = searchParams.get("upgraded");
  const {
    isPollingForUpgrade,
    pollForUpgrade,
    stopPollingForUpgrade,
    fetchBillingInfo,
    fetchUpcomingInvoice,
    billingInfo,
    upcomingInvoice,
  } = useBillingInfo({ organizationId });

  useDocumentTitle({
    type: "org",
    orgName: organizationDisplayName,
    sectionName: "Billing",
  });
  useEffect(() => {
    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organizationId}`,
        label: organizationDisplayName,
      },
      {
        path: `/organizations/${organizationId}/billing`,
        label: "Billing",
      },
    );
  }, [organizationId, organizationDisplayName]);

  useEffect(() => {
    fetchUpcomingInvoice();

    if (upgradedSearchParam === "true") {
      pollForUpgrade();
    } else {
      fetchBillingInfo();
    }

    if (upgradedSearchParam) {
      // Replace the `upgraded` flag in history, so users navigating back to
      // the page won't repeat the polling process.
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("upgraded");
      window.history.replaceState(null, "", newUrl.toString());
    }

    return () => {
      if (isPollingForUpgrade) {
        stopPollingForUpgrade();
      }
    };
    // Note: This deliberately does not react to `upgradedSearchParam` changes.
    // We only care about the value at the point of the first render, not e.g.
    // when it is cleared.
  }, []);

  const freePlanCellClasses =
    billingInfo.data && billingInfo.data.subscriptionTier === "free"
      ? "bg-green-50 dark:bg-teal-900 dark:bg-opacity-40"
      : "";
  const freePlanCellAltClasses =
    billingInfo.data && billingInfo.data.subscriptionTier === "free"
      ? "bg-green-100 dark:bg-teal-900 dark:bg-opacity-50"
      : "";
  const paygPlanCellClasses =
    billingInfo.data && billingInfo.data.subscriptionTier === "payg"
      ? "bg-green-50  dark:bg-teal-900 dark:bg-opacity-40"
      : "";
  const paygPlanCellAltClasses =
    billingInfo.data && billingInfo.data.subscriptionTier === "payg"
      ? "bg-green-100 dark:bg-teal-900 dark:bg-opacity-50"
      : "";

  return (
    <div className={"flex flex-col gap-y-8"}>
      <Title>
        <div className={"flex gap-2 items-baseline"}>
          <H2>Your Usage</H2>
        </div>
      </Title>
      <div className={"w-full max-w-xl flex flex-col gap-y-4"}>
        {upcomingInvoice.error && (
          <ErrorAlert message={`There was a problem loading your usage.`} />
        )}
        {!upcomingInvoice.data && (
          <div className="overflow-hidden border border-border rounded-xl">
            <table className={"w-full text-left w-full"}>
              <thead>
                <tr>
                  <th className={"px-4 py-2 lg:px-8 border-b"}>Product</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 lg:px-8 border-b">
                    <Skeleton className="h-6 w-56" />
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 lg:px-8 border-b">
                    <Skeleton className="h-6 w-48" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {upcomingInvoice.data && (
          <div className="overflow-hidden border border-border rounded-xl">
            <table className={"w-full text-left w-full"}>
              <thead>
                <tr>
                  <th className={"px-4 py-2 lg:px-8 border-b"}>Product</th>
                </tr>
              </thead>
              <tbody>
                {upcomingInvoice.data.lineItems.map((lineItem) => (
                  <tr key={lineItem.description} className={""}>
                    <td className="px-4 py-2 lg:px-8 border-b">
                      {lineItem.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Title>
        <div className={"flex gap-2 items-baseline"}>
          <H2>Your Plan</H2>
        </div>
      </Title>
      {isPollingForUpgrade && (
        <Alert className="max-w-4xl" data-testid="upgrading-alert">
          <LoadingSpinner className="h-4 w-4" />
          <AlertTitle>Checking for upgrade</AlertTitle>
          <AlertDescription>
            Awaiting confirmation of your upgrade.
          </AlertDescription>
        </Alert>
      )}
      {organizationRole !== "admin" && (
        <Alert className="max-w-4xl" data-testid="upgrading-alert">
          <ShieldOff className="h-4 w-4" />
          <AlertTitle>Permissions Required</AlertTitle>
          <AlertDescription>
            You must be an Organization Admin to manage your plan.
          </AlertDescription>
        </Alert>
      )}
      <div className={"w-full  max-w-4xl flex flex-col gap-y-4"}>
        {billingInfo.error && (
          <ErrorAlert message={`There was a problem loading your plan.`} />
        )}
        <div className="border border-border rounded-xl overflow-hidden">
          <table className={"w-full text-left"}>
            <thead>
              <tr className={"text-gray-800 dark:text-inherit"}>
                <th className={"px-4 py-2 border-r border-b"}></th>
                <th
                  className={`px-4 py-2 lg:px-8 font-semibold w-1/3 text-right border-r border-b ${freePlanCellClasses}`}
                >
                  <div
                    className={`w-full flex flex-row gap-x-2 items-center justify-end`}
                  >
                    <span>Free </span>
                    {billingInfo.data &&
                      billingInfo.data.subscriptionTier === "free" && (
                        <CheckCircle />
                      )}
                  </div>
                </th>
                <th
                  className={`px-4 py-2 lg:px-8 font-semibold w-1/3 text-right border-b ${paygPlanCellClasses}`}
                >
                  <div
                    className={
                      "w-full flex flex-row gap-x-2 items-center justify-end"
                    }
                  >
                    <span className={"hidden lg:block no-wrap"}>
                      Pay As You Go
                    </span>
                    <span className={"block lg:hidden"}>PAYG</span>
                    {billingInfo.data &&
                      billingInfo.data.subscriptionTier === "payg" && (
                        <CheckCircle />
                      )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className={"px-4 py-2 font-semibold border-r"}>Projects</td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right border-r ${freePlanCellClasses}`}
                >
                  1
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right ${paygPlanCellClasses}`}
                >
                  5
                </td>
              </tr>
              <tr className={"bg-muted"}>
                <td className={"px-4 py-2 font-semibold"}>
                  Web Worlds Hosting
                </td>
                <td className={`${freePlanCellAltClasses}`}></td>
                <td className={`${paygPlanCellAltClasses}`}></td>
              </tr>
              <tr className="">
                <td className={"px-4 py-2 border-r"}>Included Minutes</td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right border-r ${freePlanCellClasses}`}
                >
                  120
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right ${paygPlanCellClasses}`}
                >
                  120
                </td>
              </tr>
              <tr className="">
                <td className={"px-4 py-2 border-r"}>Additional Minutes</td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right border-r ${freePlanCellClasses}`}
                >
                  -
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right ${paygPlanCellClasses}`}
                >
                  2¢/minute
                </td>
              </tr>
              <tr className={"bg-muted"}>
                <td className={"px-4 py-2 font-semibold"}>
                  MML Object Hosting
                </td>
                <td className={`${freePlanCellAltClasses}`}></td>
                <td className={`${paygPlanCellAltClasses}`}></td>
              </tr>
              <tr className="">
                <td className={"px-4 py-2lg:px-8 border-r"}>
                  Included Minutes
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right border-r ${freePlanCellClasses}`}
                >
                  120
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right ${paygPlanCellClasses}`}
                >
                  120
                </td>
              </tr>
              <tr className="">
                <td className={"px-4 py-2 lg:px-8 border-r border-b"}>
                  Additional Minutes
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right border-r border-b ${freePlanCellClasses}`}
                >
                  -
                </td>
                <td
                  className={`px-4 py-2 lg:px-8 text-right border-b ${paygPlanCellClasses}`}
                >
                  2¢/minute
                </td>
              </tr>
              {organizationRole === "admin" && (
                <tr className="">
                  <td className={"px-4 py-2 border-r"}></td>
                  <td
                    className={`px-4 py-2 lg:px-8 border-r ${freePlanCellClasses}`}
                  >
                    <div
                      className={"w-full flex flex-row items-end justify-end"}
                    >
                      {billingInfo.data &&
                        billingInfo.data.subscriptionTier === "free" && (
                          <Button className={"w-full max-w-56"} asChild>
                            <a
                              className={"flex"}
                              href={
                                billingInfo.data
                                  .updateSubscriptionPaymentMethodUrl
                              }
                              data-testid="upgrade-button"
                            >
                              Upgrade
                            </a>
                          </Button>
                        )}
                      {!billingInfo.data && (
                        <Skeleton className={"h-10 w-full max-w-72"} />
                      )}
                    </div>
                  </td>
                  <td
                    className={`px-4 py-2 lg:px-8 text-right justify-end ${paygPlanCellClasses}`}
                  >
                    <div
                      className={"w-full flex flex-row items-end justify-end"}
                    >
                      {billingInfo.data &&
                        billingInfo.data.subscriptionTier === "payg" && (
                          <Button className={"w-full max-w-56"} asChild>
                            <a
                              className={"flex"}
                              href={billingInfo.data.manageSubscriptionUrl}
                              data-testid="manage-subscription-button"
                            >
                              Manage
                            </a>
                          </Button>
                        )}
                      {!billingInfo.data && (
                        <Skeleton className={"h-10 w-full max-w-72"} />
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
