import { Organization, OrganizationRole, Project } from "identity-api";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { Content, ErrorAlert, useDataFetcherState } from "ui-components";
import { Link, Route, Switch, useLocation } from "wouter";

import { apps } from "../../apps";
import { DashboardProps } from "../../Dashboard";
import { createIdentityServiceClient } from "../../IdentityServiceClient";
import { RenderedApp } from "../../RenderedApp";
import ProjectInfo from "./ProjectInfo";

export default function ProjectDashboard({
  user,
  sessionToken,
  onUnauthorized,
}: DashboardProps) {
  const [location] = useLocation();

  const getAccessToken = useCallback(() => {
    return Promise.resolve(sessionToken);
  }, [sessionToken]);

  const projectId = useMemo(() => {
    const splitLocation = location.split("/");
    if (splitLocation[1] === "projects" && splitLocation.length > 2) {
      return splitLocation[2];
    }
    return null;
  }, [location]);

  const projectDataFetcher = useDataFetcherState<
    Project & {
      organization: Organization;
      organizationMembership?: {
        role?: OrganizationRole;
      };
    }
  >();

  const fetchProject = useCallback(() => {
    if (!projectId) {
      return;
    }
    projectDataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getProject({
        parameters: {
          projectId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          projectDataFetcher.setData(response.body);
        } else {
          projectDataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        projectDataFetcher.setError(err);
      });
  }, [projectDataFetcher, projectId, getAccessToken, onUnauthorized]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return (
    <>
      <div className="flex flex-col flex-1 h-full">
        <Content>
          {projectDataFetcher.error?.code === 404 && (
            <div className="relative w-full h-full">
              <ErrorAlert
                message="Project not found"
                link={{
                  to: "/",
                  text: "Go Back",
                  element: Link,
                }}
              />
            </div>
          )}
          {projectDataFetcher.error?.code !== 404 && (
            <Switch>
              <Route path={`/projects/:projectId/`}>
                <ProjectInfo
                  project={projectDataFetcher.data!}
                  isLoading={projectDataFetcher.isLoading}
                  error={projectDataFetcher.error}
                  onRetry={() => fetchProject()}
                  onUpdateProject={(updatedProject) => {
                    projectDataFetcher.setData({
                      ...updatedProject,
                      organization: projectDataFetcher.data!.organization,
                      organizationMembership:
                        projectDataFetcher.data!.organizationMembership,
                    });
                  }}
                  organizationRole={
                    projectDataFetcher.data?.organizationMembership?.role
                  }
                />
              </Route>
              {apps.map((Section) => {
                return Section.items.map((app) => (
                  <Route
                    key={app.name}
                    path={`/projects/:projectId/${app.path}/*`}
                  >
                    <RenderedApp
                      key={app.name}
                      app={app}
                      projectId={projectId!}
                      projectName={projectDataFetcher.data?.name}
                      user={user}
                      getAccessToken={getAccessToken}
                      onUnauthorized={onUnauthorized}
                    />
                  </Route>
                ));
              })}
            </Switch>
          )}
        </Content>
      </div>
    </>
  );
}
