import { AuthContext } from "dashboard-common";
import { Member, OrganizationRole } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import {
  ErrorAlert,
  MenuBar,
  MenuItem,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { H2 } from "ui-components/src/components/ui";
import { Link, Route, useLocation, useParams } from "wouter";

import { useBreadcrumbs } from "../../../contexts/BreadcrumbContext";
import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import { MemberInfo } from "./MemberInfo";
import { MemberPermissionsListView } from "./MemberPermissionsListView/MemberPermissionsListView";

type MemberDashboardProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function MemberView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: MemberDashboardProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const [location] = useLocation();
  const splitLocation = location.split("/").filter((pathItem) => !!pathItem);
  const { memberId } =
    useParams<"/organizations/:organizationId/members/:memberId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);

  const currentLocation = splitLocation[splitLocation.length - 1];

  const dataFetcher = useDataFetcherState<Member>();

  const fetchMembers = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getMember({
        parameters: {
          memberId,
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          console.log(response);
          dataFetcher.setData(response.body);
        } else {
          console.error("Failed to fetch members");
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      })
      .finally(() => dataFetcher.setIsLoading(false));
  }, [getAccessToken, onUnauthorized, organizationId, dataFetcher, memberId]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const member = dataFetcher.data;

  useDocumentTitle({
    type: "org",
    orgName: organizationDisplayName,
    sectionName: "Members",
  });
  useEffect(() => {
    if (!member?.userInfo) {
      return;
    }

    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organizationId}`,
        label: organizationDisplayName,
      },
      {
        path: `/organizations/${organizationId}/members`,
        label: "Members",
      },
      {
        path: `/organizations/${organizationId}/members/${member.id}`,
        label: member.userInfo.displayName || memberId,
      },
    );
  }, [organizationId, organizationDisplayName, member]);

  return (
    <div className="w-full mx-auto mt-10">
      {dataFetcher.error && (
        <div className="w-full relative">
          {dataFetcher.error.code === 404 ? (
            <ErrorAlert
              message="Member not found"
              link={{
                to: `/organizations/${organizationId}/members/`,
                text: "Go Back",
                element: Link,
              }}
            />
          ) : (
            <ErrorAlert
              message={`There was a problem fetching the member: ${dataFetcher.error.message}`}
              action={{
                text: "Retry",
                cb: () => {
                  fetchMembers();
                },
              }}
            />
          )}
        </div>
      )}
      {!dataFetcher.error && (
        <>
          {dataFetcher.isLoading ? (
            <div className="mb-10">
              <H2>Loading...</H2>
            </div>
          ) : (
            <>
              <MenuBar className="mb-10">
                <MenuItem
                  active={!["members", "permissions"].includes(currentLocation)}
                  to={`/organizations/${organizationId}/members/${memberId}`}
                  LinkElement={Link}
                >
                  Info
                </MenuItem>
                <MenuItem
                  active={currentLocation === "permissions"}
                  to={`/organizations/${organizationId}/members/${memberId}/permissions`}
                  LinkElement={Link}
                >
                  Permissions
                </MenuItem>
              </MenuBar>
              <Route path={"/organizations/:organizationId/members/:memberId/"}>
                <MemberInfo
                  member={member}
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationRole!}
                />
              </Route>
              <Route
                path={
                  "/organizations/:organizationId/members/:memberId/permissions"
                }
              >
                <MemberPermissionsListView
                  memberId={memberId}
                  member={member}
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationRole!}
                />
              </Route>
            </>
          )}
        </>
      )}
    </div>
  );
}
