import { CookieIcon } from "lucide-react";
import * as React from "react";
import { useLayoutEffect, useState } from "react";
import { Button, cn } from "ui-components";

export default function CookieBanner() {
  const [hasHandledCookies, setHasHandledCookies] = useState(false);

  const rejectCookies = () => {
    document.cookie = "cookiesAccepted=false;path=/;samesite=strict";
    setHasHandledCookies(true);
  };

  const acceptCookies = () => {
    document.cookie = "cookiesAccepted=true;path=/;samesite=strict";
    setHasHandledCookies(true);
  };

  useLayoutEffect(() => {
    const cookies = document.cookie.split("; ");
    const cookieValue = cookies.find((row) =>
      row.startsWith("cookiesAccepted="),
    );
    if (cookieValue) {
      setHasHandledCookies(true);
    }
  }, []);

  if (hasHandledCookies) {
    return null;
  }

  return (
    <div
      className={cn(
        "fixed z-50 bottom-0 right-0 w-full md:max-w-md duration-700 p-3",
      )}
    >
      <div className="m-3 dark:bg-card bg-background border border-border rounded-lg">
        <div className="flex items-center justify-between p-3">
          <h1 className="text-lg font-medium">We use cookies</h1>
          <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
        </div>
        <div className="p-3 -mt-2">
          <p className="text-sm text-left text-muted-foreground">
            We use essential cookies to make our site work. With your consent,
            we may also use non-essential cookies to improve user experience and
            analyse website traffic. By clicking ‘Accept’, you agree to our
            website’s cookie use as described in our{" "}
            <a
              href="https://msquared.io/cookie-policy"
              target="_blank"
              rel="noreferrer"
              className={"underline"}
            >
              Cookie Policy
            </a>
            .
          </p>
        </div>
        <div className="p-3 flex items-center gap-2 mt-2 border-t">
          <Button onClick={acceptCookies} className="w-full h-9 rounded-md">
            Accept
          </Button>
          <Button
            onClick={rejectCookies}
            className="w-full h-9 rounded-md"
            variant="outline"
          >
            Reject
          </Button>
        </div>
      </div>
    </div>
  );
}
