import { Building, ChevronRight, Folder, LayoutGrid, Plus } from "lucide-react";
import * as React from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownMenuItem,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
} from "ui-components";
import { SidebarGroupContent } from "ui-components/src";
import { Link, useLocation } from "wouter";

import { apps } from "../../apps";
import { useProject } from "../../contexts/ProjectContext";
import CreateOrganizationModal from "../ProfileView/CreateOrganizationModal";
import CreateProjectModal from "../ProjectsDashboard/CreateProjectModal";
import { Branding } from "./Branding";
import { SelectableDropdown } from "./SelectableDropdown";
import { SupportMenu } from "./SupportMenu";
import { UserMenu } from "./UserMenu";

type Props = {
  projectId: string;
};

export function SidebarProject({ projectId }: Props) {
  const [location] = useLocation();
  const currentAppPath = location.split("/")[3];
  const currentPath = location.split("/")[4];
  const {
    getProjectById,
    getProjectsInOrgByProjectId,
    getOrganizationByProjectId,
    getOrganizations,
    refresh,
  } = useProject();
  const [, navigate] = useLocation();
  const project = getProjectById(projectId);
  const projects = getProjectsInOrgByProjectId(projectId);
  const organization = getOrganizationByProjectId(projectId);
  const organizations = getOrganizations();

  if (!project || !organization) {
    refresh();
    return <Sidebar variant="inset"></Sidebar>;
  }

  return (
    <Sidebar variant="inset">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <Branding />
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SelectableDropdown
              icon={<Building />}
              text={organization.name}
              link={`/organizations/${organization.id}`}
              createComponent={
                <CreateOrganizationModal
                  onOrganizationCreated={(organization) => {
                    navigate(`/organizations/${organization.id}`);
                  }}
                >
                  <DropdownMenuItem
                    className="gap-2 p-2"
                    onSelect={(e) => e.preventDefault()}
                  >
                    <div className="flex size-6 items-center justify-center rounded-sm border">
                      <Plus className="size-4" />
                    </div>
                    Create Organization
                  </DropdownMenuItem>
                </CreateOrganizationModal>
              }
              options={organizations!.map((organization) => {
                return {
                  text: organization.name,
                  link: `/organizations/${organization.id}/`,
                };
              })}
            />
            <SelectableDropdown
              icon={<Folder />}
              text={project.name}
              link={`/projects/${projectId}/`}
              options={projects!.map((project) => {
                return {
                  text: project.name,
                  link: `/projects/${project.id}/`,
                };
              })}
              createComponent={
                <CreateProjectModal
                  withinOrganizationId={organization.id}
                  onProjectCreated={(project) => {
                    navigate(`/projects/${project.id}/`);
                  }}
                  disabled={false}
                >
                  <DropdownMenuItem
                    className="gap-2 p-2"
                    onSelect={(e) => e.preventDefault()}
                  >
                    <div className="flex size-6 items-center justify-center rounded-sm border">
                      <Plus className="size-4" />
                    </div>
                    Create Project
                  </DropdownMenuItem>
                </CreateProjectModal>
              }
            />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        {apps.map((section) => (
          <SidebarGroup key={section.name}>
            <SidebarGroupLabel>{section.name}</SidebarGroupLabel>
            <SidebarMenu>
              <SidebarMenuItem>
                <Link to={`/projects/${projectId}/`}>
                  <SidebarMenuButton isActive={!currentAppPath}>
                    <LayoutGrid />
                    <span>Overview</span>
                  </SidebarMenuButton>
                </Link>
              </SidebarMenuItem>
              {section.items.map((item) => (
                <Collapsible
                  key={item.name}
                  asChild
                  defaultOpen={true}
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton>
                        {item.icon && <item.icon />}
                        <span>{item.name}</span>
                        <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.subsections?.map((subsection) => (
                          <SidebarMenuSubItem key={subsection.name}>
                            <SidebarMenuSubButton
                              asChild
                              isActive={
                                currentAppPath === item.path &&
                                currentPath === subsection.path
                              }
                            >
                              <Link
                                to={`/projects/${projectId}/${item.path}/${subsection.path}/`}
                                data-testid={`sidebar-${item.path}-${subsection.path}`}
                              >
                                <span>{subsection.name}</span>
                              </Link>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              ))}
            </SidebarMenu>
          </SidebarGroup>
        ))}
        <SidebarGroup className="mt-auto">
          <SidebarGroupContent>
            <SupportMenu />
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <UserMenu />
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
