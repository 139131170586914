import { AuthContext } from "dashboard-common";
import {
  ListInvitesSuccessResponse,
  OrganizationInvite,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { ListElementSkeleton } from "ui-components/src";

import { useBreadcrumbs } from "../../../contexts/BreadcrumbContext";
import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import CreateInviteModal from "./CreateInviteModal";
import DeleteInviteModal from "./DeleteInviteModal";
import InviteCreatedModal from "./InviteCreatedModal";

type InviteListViewProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole: OrganizationRole;
};

export function InvitesListView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: InviteListViewProps) {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher = useDataFetcherState<ListInvitesSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [createdInvite, setCreatedInvite] = useState<{
    invite: OrganizationInvite;
    inviteUrl: string;
  } | null>(null);

  useDocumentTitle({
    type: "org",
    orgName: organizationDisplayName,
    sectionName: "Invites",
  });
  useEffect(() => {
    setBreadcrumbs(
      {
        path: "/organizations",
        label: "Organizations",
      },
      {
        path: `/organizations/${organizationId}`,
        label: organizationDisplayName,
      },
      {
        path: `/organizations/${organizationId}/invites`,
        label: "Invites",
      },
    );
  }, [organizationId, organizationDisplayName]);
  const fetchInvites = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listInvites({
        parameters: {
          organizationId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    if (organizationRole === "admin") {
      fetchInvites();
    } else {
      dataFetcher.setIsLoading(false);
    }
  }, [fetchInvites, currentPage, itemsPerPage, dataFetcher, organizationRole]);

  return (
    <>
      {createdInvite && (
        <InviteCreatedModal
          inviteUrl={createdInvite.inviteUrl}
          onClose={() => {
            setCreatedInvite(null);
            fetchInvites();
          }}
        />
      )}
      <Title>
        <div className="flex gap-2 items-baseline">
          <H2>Invites</H2>
          <DocsLink path="/organizations/invites" text="Docs" />
        </div>
        <CreateInviteModal
          organizationId={organizationId}
          onInviteCreated={(
            invite: OrganizationInvite,
            inviteToken: string,
          ) => {
            Sonner.toast("Invite created");
            fetchInvites();
            setCreatedInvite({
              invite,
              inviteUrl: `${window.location.origin}/accept-invite/${organizationId}/${invite.id}?token=${inviteToken}`,
            });
          }}
          disabled={organizationRole !== OrganizationRoleAdmin}
        />
      </Title>
      <div className="h-full">
        <div className="w-full min-h-full mx-auto pb-20">
          <ItemPagination
            loading={dataFetcher.isLoading}
            className="relative pt-4"
            totalItems={dataFetcher.data?.totalResults || null} // total number of available items
            itemsPerPage={itemsPerPage}
            currentPage={currentPage} // initial page
            setPage={setCurrentPage} // callback to set the current page
          >
            <BasicList>
              {dataFetcher.error && (
                <ErrorAlert
                  message={`There was a problem fetching the invites: ${dataFetcher.error?.message}`}
                  action={{
                    text: "Retry",
                    cb: () => {
                      fetchInvites();
                    },
                  }}
                />
              )}
              {dataFetcher.isLoading &&
                Array.from(Array(12).keys()).map((i) => (
                  <ListElementSkeleton key={i} />
                ))}
              {dataFetcher.data &&
                dataFetcher.data.organizationInvites.map((invite) => (
                  <ListElement key={invite.id} noLink>
                    <div className="flex-col flex text-sm basis-2/5">
                      Email:
                      <span className="text-xl font-semibold">
                        {invite.email}
                      </span>
                    </div>
                    <div className="flex-col flex text-sm">
                      Role:
                      <span className="text-xl font-semibold">
                        {invite.role}
                      </span>
                    </div>
                    <div className="ml-auto flex gap-2 mr-4">
                      <DeleteInviteModal
                        inviteId={invite.id}
                        organizationId={organizationId}
                        onInviteDeleted={() => {
                          fetchInvites();
                          Sonner.toast("Invite deleted");
                        }}
                      />
                    </div>
                  </ListElement>
                ))}
              {dataFetcher.data &&
                !dataFetcher.data.organizationInvites.length && (
                  <EmptyState message="There are no invites" />
                )}
              {organizationRole &&
                organizationRole !== OrganizationRoleAdmin && (
                  <EmptyState message="You don't have permission to view invites for this organizations" />
                )}
            </BasicList>
          </ItemPagination>
        </div>
      </div>
    </>
  );
}
